<template>
  <v-container fluid :class="{'pt-0': $vuetify.breakpoint.xsOnly}">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" dark>
            <v-toolbar-title tabs>Player Rankings</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row dense justify="center">
              <v-col class="text-center" v-for="r in rankings" :key="r.id" >
                <v-card class="pa-2">
                  <v-img
                    @click.native="goto(r)"
                    max-height="200px"
                    :src="r.props.logo"
                    aspect-ratio="1"
                    contain
                  ></v-img>
                  <div v-if="r.props.title" class="text-h4 grey--text">
                    {{ r.props.title }}
                  </div>
                  <v-btn
                    color="color3 color3Text--text"
                    :to="r.route"
                    class="mt-3"
                    block
                  >View Current Rankings</v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['rankings'])
  },
  methods: {
    goto (r) {
      this.$router.push(r.route)
    }
  }
}
</script>

<style>

</style>
